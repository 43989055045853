import React from "react"
import "./Nosotros.css"
import Footers from "../Layouts/footer/Footer";
import { Card } from "react-bootstrap";


const Nosotros = () => {
    return(
        <section className="nosotros" >
            <img className="d-block w-100 container" src='./images/cover.jpg'alt="First slide"/>
            <dir></dir>
            <div class="card mb-3 container" styled="max-width: 700px;">
              <div class="row g-0">
                   <div class="col-md-6">
                      <img className="img img-fluid rounded float-end" src="./images/abouts.jpg" alt="about"></img>
                   </div>
                   <div class="col-md-6">
                      <div class="card-body">
                          <h2>Acerca de Nosotros</h2>
                          <h1>Los Reyes de tus Envios</h1>
                          <p>Logistica Reyes nacio de ver como los negocios tradicionales se veian limitados al mercado local, y el como les era dificil seguir en contacto con sus clientes de otros estados de la republica, los cuales tenian dificultades para hacer llegar sus productos de calidad a mas personas.
                          </p>
                          <p>Por eso cuidamos tu mercancia como si fuera nuestra, por eso escogemos los mejores aliados, las marcas mas reconocidas y con los mejores precios para que tu solo te preocupes por vender.
                          </p>
                          <p>Iniciamos este proyecto en 2007 abriendo nuestra primera sucursal en la Nueva Central Camionera de Guadalajara, y hoy estamos contigo en 3 de las ciudades mas mas importantes de Jalisco, ampliando nuestra gama de servicios especializados.
                          </p>
                      </div>
                   </div>
               </div>
            </div>
            <dir></dir>
            <Card body className="Container">
              <div className="row container">
                   <div className="md-2">
                       <h1>Mision</h1>
                       <div className="sub-underline"></div>
                       <p>Ofrecer soluciones logísticas a los clientes, superando sus expectativas de servicio. </p>
                   </div>
               </div>
               <div className="row container">
                  <div className="md-2">
                      <h1>Vision</h1>
                      <div className="sub-underline"></div>
                      <p>Ser reconocidos como empresa de mensajería, paquetería y soluciones logísticas líder en el medio a nivel nacional e internacional.  </p>
                  </div>
               </div>
            </Card>
            <div className="container px-4 py-5" id="featured-3">
               <h2 className="pb-2 border-bottom">Valores de la Empresa</h2>
               <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
                   <div class="feature col">
                       <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                         <svg class="bi" width="1em" height="1em"></svg>
                       </div>
                       <h3 class="fs-2"><i class="bi bi-person-circle px-4"></i>Integridad</h3>
                       <p>Es la capacidad de actuar con honestidad y ser congruente con las palabras, decisiones y acciones. Este valor se relaciona a la persona que elige hacer lo correcto y no lo que le conviene ya que esta fiel a sus principios. </p>
                   </div>
                   <div class="feature col">
                       <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                         <svg class="bi" width="1em" height="1em"></svg>
                       </div>
                       <h3 class="fs-2"><i class="bi bi-person-circle px-4"></i>Compromiso</h3>
                       <p>Significa el cumplimiento de las tareas y la ejecución de responsabilidades en el tiempo necesario y con la calidad esperada. Es incluir voluntad, puntualidad, iniciativa y cooperación para el logro de objetivos.</p>
                  </div>
                  <div class="feature col">
                       <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                          <svg class="bi" width="1em" height="1em"></svg>
                       </div>
                       <h3 class="fs-2"><i class="bi bi-person-circle px-4"></i>Confianza</h3>
                       <p>El valor de la confianza, se define como la esperanza de que una persona o el servicio, se comporte o funcione según lo previsto. Confiar es dejarse llevar, sin dudar. Confiar en las personas y sus acciones, fortalece los equipos de trabajo.</p>
                  </div>
                  <div class="feature col">
                       <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                          <svg class="bi" width="1em" height="1em"></svg>
                       </div>
                       <h3 class="fs-2"><i class="bi bi-person-circle px-4"></i>Humildad</h3>
                       <p>Consiste en conocer las propias limitaciones y debilidades, y actual conforme a tal conocimiento, así como asumir las fortalezas sin vanagloriarse. Es la capacidad de aprender, enseñar y escuchar, mostrando un trato amable hacia a todas las personas.</p>
                  </div>
                  <div class="feature col">
                       <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                          <svg class="bi" width="1em" height="1em"></svg>
                       </div>
                       <h3 class="fs-2"><i class="bi bi-person-circle px-4"></i>Respeto</h3>
                       <p>Implica la aceptación total de la persona sin señalar su forma de ser ni sus creencias. Es atender con consideración a quienes nos rodean y darle el uso adecuado a los bienes y recursos. Se considera un valor de reciprocidad: ¡Te respeto y espero lo mismo de ti!</p>
                  </div>
                  <div class="feature col">
                       <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                          <svg class="bi" width="1em" height="1em"></svg>
                       </div>
                       <h3 class="fs-2"><i class="bi bi-person-circle px-4"></i>Vocación</h3>
                       <p>Engrandece al ser humano el servir a los demás. El servicio es la disposición para que sea ágil, eficiente, oportuno y amable. La persona servicial cuenta con un alto sentido de colaboración; su ayuda a los demás es de manera espontánea y siempre con una buena actitud.</p>
                  </div>
              </div>
          </div>
          <Footers></Footers>
     
        </section>
    )
}

export default Nosotros