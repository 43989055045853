import React from 'react';
import "./Carousel.css"
import Button from 'react-bootstrap/Button';
import { Carousel } from 'react-bootstrap';


const CarouselContainer = () => {
  const images_url = [
    "./images/1.jpg",
    "./images/Baner2.jpg",
    "./images/3.jpg",
    "./images/horizontal_images/horizontal_images1.jpg",
    "./images/horizontal_images/horizontal_images2.jpg",
    "./images/horizontal_images/horizontal_images3.jpg",
    "./images/horizontal_images/horizontal_images4.jpg",
    "./images/horizontal_images/horizontal_images5.jpg",
    "./images/horizontal_images/horizontal_images6.jpg",
    "./images/horizontal_images/horizontal_images7.jpg"
  ];

  const CarousellItems = images_url.map((image_url,i)=>{
    console.log(image_url);
    return(
      <Carousel.Item className="altura" interval={3000} key={i}>
        <img src={`${image_url}`} className="d-block w-100" alt="First slide"/>
        <Carousel.Caption className='but'>
          <div className='text'>
            <h3>KL ENTREGABLE</h3>
            <p>Todos mandan paquetes, nosotros conectamos negocios.</p>
          </div>
          <div className="contenedor-botones">
              <Button className="boton" href="./Rastreo">
                <span>Rastrea un Paquete</span>
                <i class="bi ms-2 bi-box-seam"></i>            
              </Button>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    );
  });
  return (
    <Carousel className='clase' fade={true} pause={false}>
      {CarousellItems}
    </Carousel>
  )
}

export default CarouselContainer;