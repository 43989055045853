import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

//importamos los comp creados
import Inicio from './Components/Inicio';
import Nosotros from './Components/nosotros/Nosotros';
import Cotizacion from './Components/cotizacion/Cotizacion';
import RastreoEntregable from './Components/rastreo/RastreoEntregable';
import RastreoEstafeta from './Components/rastreo/RastreoEstafeta';
import FAQ from './Components/preguntas/FAQ';
import Contacto from './Components/contacto/Contacto';
import Resultados from './Components/Resultados';
import Footers from './Components/Layouts/footer/Footer' 

import NavBarExample from './Components/Layouts/Navbar';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
       <Routes>
           <Route path='/' element={ <NavBarExample /> }>
           <Route index element={ <Inicio /> } />
           <Route path='Nosotros' element={ <Nosotros /> } />
           <Route path='Cotizacion' element={ <Cotizacion /> } />
           <Route path='FAQ' element={ <FAQ /> } />
           <Route path='Contacto' element={ <Contacto /> } />
           <Route path='Resultados' element={ <Resultados /> } />
           <Route path='Rastreo' element={ <RastreoEntregable /> } />
           <Route path='Rastreo-Estafeta-V1' element={ <RastreoEstafeta/> } />
           <Route path='*' element={ <Navigate replace to="/"/> }/>
           </Route>
        </Routes> 
      </BrowserRouter>
    </div> 
  );
}

export default App;
