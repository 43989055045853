import React from "react"
import "./Card.css"


const Cards = () => {
  return( 
   <div className="css container">
     <h2>Nuestros Aliados</h2>
     <div className="row row-cols-1 row-cols-md-3 g-3 py-3 cont">
        <div className="col">
          <div className="Card">
            <div className="card-body">
              <h5 className="card-title">La Mexicana</h5>
              <p className="card-text">La empresa mexicana mas importante y con mayor cobertura a nivel nacional.</p>
            </div>
            <img src="./images/Curiers/Curier_01.png" className="card-img-top" alt="..."></img>
          </div>
        </div>
        <div className="col">
          <div className="Card">
            <div className="card-body">
              <h5 className="card-title">La Americana</h5>
              <p className="card-text">Una de las empresas de logistica mas eficientes de los ultimos tiempos en la Industria.</p>
            </div>
            <img src="./images/Curiers/Curier_02.png" className="card-img-top" alt="..."></img>
          </div>
        </div>
        <div className="col">
          <div className="Card">
            <div className="card-body">
              <h5 className="card-title">La Alemana</h5>
              <p className="card-text">Una empresa de logística líder en el mundo, sin fronteras y de mayor cobertura.</p>
            </div>
            <img src="./images/Curiers/Curier_03.png" className="card-img-top" alt="..."></img>
          </div>
        </div>
     </div>
   </div>
      
  )
}
export default Cards


