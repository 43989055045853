import { Navbar, Nav, Container, /* NavDropdown */ } from "react-bootstrap"
import { Outlet, Link } from "react-router-dom"

const NavBarExample = () => {
  return(
     <>    
     <Navbar className="navBg d-flex justify-content-center" variant="dark" expand="lg">
      <Container>
          <img src= './logo_entregable.png' alt="" width={300}></img>
          <Navbar.Brand as={Link} to="/" ></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" class="d-flex flex-row-reverse">
           <Nav className="mx-auto">
              <Nav.Link as={Link} to="/">Inicio</Nav.Link>
              <Nav.Link as={Link} to="/Nosotros">Nosotros</Nav.Link>
              <Nav.Link as={Link} to="/Rastreo">Rastreo</Nav.Link>
              {/* <Nav.Link as={Link} to="/FAQ">FAQ</Nav.Link>        */}         
              <Nav.Link as={Link} to="/Contacto">Contacto</Nav.Link>  
              {/* <NavDropdown title="Servicios">
                <NavDropdown.Item href="Cotizacion">Cotizacion</NavDropdown.Item>
                <NavDropdown.Item href="Rastreo">Rastreo</NavDropdown.Item>
              </NavDropdown> */}
           </Nav>
         </Navbar.Collapse>
      </Container>
      </Navbar>  

      <section>
          <Outlet></Outlet>
      </section> 
     </> 
  )
}
export default NavBarExample



