import React from "react"
import Footers from "../Layouts/footer/Footer";
import "./Contacto.css"
import { Nav } from "react-bootstrap"


const Contactos = () => {
    return(
        <div>
         <div className="texto">
              <p>¿Comunícate con nosotros?<i class="bi bi-box-seam justify-content-between ms-4"></i></p>
         </div>
           <div class="card-group col-sm-5 container ">
              {/* <div class="card">
                   <img src="./images/nuevosc.jpg" class="card-img-top" alt="..."></img>
                   <div class="card-body text-left">
                       <h5 class="card-title ">Nuevos Clientes</h5>
                       <p class="card-text ">Somos tu primera elccion.</p>
                       <div class="card-footer">
                          <p>Estado Personal y Empresarial:</p>
                          <ul class="list-group list-group-flush">
                              <Nav.Link href="https://www.facebook.com/logisticareyesmx" class="card-link"><i class="bi bi-facebook"></i>Facebook</Nav.Link>
                              <Nav.Link href="https://www.whatsapp.com/?lang=es" class="card-link"><i class="bi bi-whatsapp"></i>whatsapp</Nav.Link>
                              <Nav.Link href="" class="card-link"><i class="bi bi-telephone-fill"></i>Phone: +52 33 1593 3925</Nav.Link>
                              <Nav.Link href="./FAQ" class="card-link"><i class="bi bi-question-circle-fill"></i>Preguntas Frecuentes</Nav.Link>
                              <Nav.Link href="https://www.google.com/intl/es-419/gmail/about/" class="card-link"><i class="bi bi-envelope-fill"></i>Envie un correo electronico.</Nav.Link>
                           </ul>
                       </div>
                  </div>
               </div>
               <div class="card">
                   <img src="./images/clientes.jpg" class="card-img-top" alt="..."></img>
                   <div class="card-body text-left">
                       <h5 class="card-title ">Clientes</h5>
                       <p class="card-text ">Somos tu primera elccion.</p>
                       <div class="card-footer">
                          <p>Consumidores:</p>
                          <ul class="list-group list-group-flush">
                              <Nav.Link href="https://www.google.com/intl/es-419/gmail/about/" class="card-link"><i class="bi bi-envelope-fill"></i>Envie un correo electronico.</Nav.Link>
                              <Nav.Link href="https://www.whatsapp.com/?lang=es" class="card-link"><i class="bi bi-whatsapp"></i>whatsapp</Nav.Link>
                              <Nav.Link href="" class="card-link"><i class="bi bi-telephone-fill"></i>Phone: +52 33 1593 3925</Nav.Link>
                           </ul>
                       </div>
                   </div>
               </div> */}
               <div class="card" >
                    <img src="./images/clientes.jpg" class="card-img-top" alt="..." ></img>
                    <div class="card-body text-left">
                       <h5 class="card-title">Servicio de Paquetes</h5>
                       <p class="card-text">Somos tu primera elccion.</p>
                       <div class="card-footer">
                          <p> Solamente Negocios</p>
                          <ul class="list-group list-group-flush">
                              <Nav.Link href="https://www.facebook.com/ENTREGABLE/" class="card-link"><i class="bi bi-facebook m-2"></i>Facebook</Nav.Link>
                              <Nav.Link href="https://www.instagram.com/klentregable/" class="card-link"><i class="bi bi-instagram m-2"></i>Instagram</Nav.Link>
                              <Nav.Link href="https://www.whatsapp.com/?lang=es" class="card-link"><i class="bi bi-whatsapp m-2"></i>whatsapp</Nav.Link>
                              <Nav.Link href="" class="card-link"><i class="bi bi-telephone-fill m-2"></i>Telefono: +52 33 3803 2268</Nav.Link>
                              {/* <Nav.Link href="./FAQ" class="card-link"><i class="bi bi-question-circle-fill m-2"></i>Preguntas Frecuentes</Nav.Link> */}
                           </ul>
                       </div>
                   </div>
              </div>
           </div>

           <Footers></Footers>

        </div>
    )
}

export default Contactos