import React from "react";
import Cards from "./Layouts/cards/Card";
import CarouselContainer from "./Layouts/carousel/Carousel";
/*  */
import Footers from "./Layouts/footer/Footer";
import Abouts from "./Layouts/about/About";

const Inicio = () => {
  return (
    <div>
      <CarouselContainer></CarouselContainer>
      <p></p>
      {/*  <Rast></Rast> */}
      <p></p>
      <Abouts></Abouts>
      <p></p>
      <p></p>
      <p></p>
      <Cards></Cards>
      <Footers></Footers>
    </div>
  );
};

export default Inicio;
