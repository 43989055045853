import React from "react";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";

const Footers = () => {
  return (
    <div className="bg-black text-wihte py-5">
      <div className="container aling-center">
        <div className="row">
          <div className="col-md-6">
            <h6>HORARIO DE ATENCIÓN</h6>
            <hr></hr>
            <p>Sabados y Domingo cerrado</p>
            <div>
              <Link to="/" className="text-decoration-none text-white">
                Lunes a Viernes
              </Link>
            </div>
            <div>
              <Link to="/" className="text-decoration-none text-white">
                {" "}
                9:30am a 6:00pm
              </Link>
            </div>
            <hr></hr>

            <div>
              <Link
                to="/Rastreo-Estafeta-V1"
                className="text-decoration-none text-white"
              >
                {" "}
                Rastreo Estafeta V1.0
              </Link>
            </div>
          </div>
          <div className="col-md-6">
            {/** 
            <h6>Empresa Logistca Reyes</h6>
             * 
            */}
            <hr></hr>
            <p>Todos mandan paquetes, nosotros conectamos negocios.</p>
            <p></p>
            
            <p></p>
            <div>
              <Link to="/" className="text-decoration-none text-white">
                telefono: +52 33 3803 2268
              </Link>
            </div>
            <ul className="col-12 col-md-3">
              <li className="d-flex justify-content-evenly">
                <Nav.Link
                  href="https://www.facebook.com/ENTREGABLE/"
                  target="_blank"
                  class="card-link text-decoration-none text-white"
                >
                  <i class="bi bi-facebook"></i>
                </Nav.Link>
                <Nav.Link
                  href="https://wa.me/523338032268?text=Hola%20me%20gustaria%20pedir%20informes%20sobre%20sus%20servicios."
                  target="_blank"
                  class="card-link text-decoration-none text-white"
                >
                  <i class="bi bi-whatsapp"></i>
                </Nav.Link>
                <Nav.Link
                  href="https://www.instagram.com/klentregable/"
                  target="_blank"
                  class="card-link text-decoration-none text-white"
                >
                  <i class="bi bi-instagram"></i>
                </Nav.Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footers;
