import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import React, {useState} from "react"
import "./Cotizacion.css"

const Cotizacion = () => {

  const [registo, setRegistro] = useState({
    origen: "",
    destino:"",
    peso:"",
    alto:"",
    largo:"",
    ancho:""
  })

  const manejarFormulario = (event) =>{
    const {name, value} = event.target;
    setRegistro(prevRegistro =>({ 
      ...prevRegistro,
      [name]:value
    })) 
    console.log(registo);
  }

  const[visible, setVisible]= useState(false)

  

  return(
    <div className="col">
      <h2>Citizacion de envios</h2>
      <form className="cart">
        <div className="py-3 container">
          <Form.Label className='row col-sm-10' htmlFor=''>CP de Origen</Form.Label>
          <Form.Control type="text" value={registo.origen} name="origen" onChange={manejarFormulario}></Form.Control>
        </div>
        <div className="py-3 container">
          <Form.Label className='row col-sm-10' htmlFor=''>CP de Destino</Form.Label>
          <Form.Control type="text" value={registo.destino} name="destino" onChange={manejarFormulario}></Form.Control>
        </div>
        
        <div className="py-3">
          <p>Tipo Paquete</p> 
          Paquete<input type="Radio" name='tipo' value="paquete" className='mx-2 mt-1' onClick={()=>setVisible(true)}></input>
          Sobre<input type="Radio" name='tipo' value="sobre" className='mx-2 mt-1' onClick={()=>setVisible(false)}></input>
        </div>
        {visible &&
         <Form>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className='row col-sm-10' htmlFor=''>Peso kg</Form.Label>
                <Form.Control type="text" value={registo.peso} name="peso" onChange={manejarFormulario}></Form.Control>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='row col-sm-10' htmlFor=''>Altura cms</Form.Label>
                <Form.Control type="text" value={registo.alto} name="alto" onChange={manejarFormulario}></Form.Control>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className='row col-sm-10' htmlFor=''>Largo cms</Form.Label>
                <Form.Control type="text" value={registo.largo} name="largo" onChange={manejarFormulario}></Form.Control>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='row col-sm-10' htmlFor=''>Ancho cms</Form.Label>
                <Form.Control type="text" value={registo.ancho} name="ancho" onChange={manejarFormulario}></Form.Control>
              </Form.Group>
            </Row>
         </Form>
        }

        <Button type="submit" variant="warning">enviar </Button>

      </form>
    </div>
  )
}

export default Cotizacion