import React from "react";
import "./About.css"
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';

const Abouts = () => {
  const information = [
    {
      title:"KL Entregable ¡Última milla!.",
      description:"No dejes pasar la oportunidad.KL Entregable te ofrece su servicio ¡Última milla!. Comunícate con nosotros para saber más.KL Entregable está más cerca de ti.",
      image:"./images/square_images/square_images_03.png",
    },
    {
      title:"¡Estamos contratando!",
      description:"Forma parte de esta gran familia. Postula para cualquiera de nuestras vacantes.Comunicate con nosotros para mas informacion.",
      image:"./images/square_images/square_images_02.png",
    },
    {
      title:"¡Envios Nacionales!",
      description:"Envios nacionales, a precios accesibles, ahora tu negocio no tiene barreras cotiza y envia a todo Mexico",
      image:"./images/square_images/square_images_01.png",
    },
    {
      title:"¡Envios grandes en Tarimas!",
      description:"Cotiza con nosotros y recibe precios especiales en paquetes de volumen. Nos hacemos cargo de tu logistica Nacional e internacional.",
      image:"./images/square_images/square_images_04.png",
    }
  ]
  const CardImages = information.map((item,i)=>{
    return(
      <Card key={i} className="px-2 ">
        <Card.Img variant="top" src={`${item.image}`} />
        <Card.Body>
          <Card.Title className="titulo">{item.title}</Card.Title>
          <Card.Text className="descripcion">{item.description}</Card.Text>
        </Card.Body>
        <Card.Footer><small className="text-muted"></small></Card.Footer>
      </Card>
    )
  });
  return( 
    <div className="sim">
      <dir></dir>
      <CardGroup className="container">
        {CardImages}
      </CardGroup>
      <dir></dir>
    </div>
  )
}

export default Abouts