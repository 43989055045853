import React from 'react';
import "./Rastreo.css";
import Footers from '../Layouts/footer/Footer';

function RastreoEntregable() {
    return (
        <div className='rastreo'>
            <iframe src='https://rastreo.public.entregable.com.mx/'></iframe>
            <Footers></Footers>
        </div>
                )

}

export default RastreoEntregable;