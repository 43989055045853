import React from 'react';
import "./Rastreo.css";
import Footers from '../Layouts/footer/Footer';

function RastreoEstafeta() {
    return (
        <div className='rastreo'>
            <h3>Rastreo Estafeta</h3>
            <iframe src='https://rastreositecorecms.azurewebsites.net/'></iframe>
            <Footers></Footers>
        </div>
                )

}

export default RastreoEstafeta;